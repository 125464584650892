// Generated by Framer (20caf11)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./H7915NljF-0.js";

const cycleOrder = ["yLiQxo5kr", "ckyPSVg2w"];

const serializationHash = "framer-XpEZd"

const variantClassNames = {ckyPSVg2w: "framer-v-1lvej8j", yLiQxo5kr: "framer-v-1lrtynv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {XCjGPTapi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Menu Close": "yLiQxo5kr", "Menu Open": "ckyPSVg2w"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, cysd_f03b: tap ?? props.cysd_f03b, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yLiQxo5kr"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, cysd_f03b, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yLiQxo5kr", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapaahp08 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (cysd_f03b) {const res = await cysd_f03b(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lrtynv", className, classNames)} data-framer-name={"Menu Close"} data-highlight layoutDependency={layoutDependency} layoutId={"yLiQxo5kr"} onTap={onTapaahp08} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({ckyPSVg2w: {"data-framer-name": "Menu Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-8y2bfo"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"J1jUe5RRd"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 90}} variants={{ckyPSVg2w: {rotate: 360}}}/><motion.div className={"framer-nee6ms"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"PvzS4hW9x"} style={{backgroundColor: "var(--token-b740f1d8-819f-424e-8ed4-13557f40bfa1, rgb(20, 34, 81))", borderBottomLeftRadius: 2, borderBottomRightRadius: 2, borderTopLeftRadius: 2, borderTopRightRadius: 2, rotate: 0}} variants={{ckyPSVg2w: {rotate: 270}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XpEZd.framer-164l23h, .framer-XpEZd .framer-164l23h { display: block; }", ".framer-XpEZd.framer-1lrtynv { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 20px; }", ".framer-XpEZd .framer-8y2bfo, .framer-XpEZd .framer-nee6ms { flex: none; height: 2px; left: 0px; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"ckyPSVg2w":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"cysd_f03b":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerH7915NljF: React.ComponentType<Props> = withCSS(Component, css, "framer-XpEZd") as typeof Component;
export default FramerH7915NljF;

FramerH7915NljF.displayName = "Icon Menu Copy";

FramerH7915NljF.defaultProps = {height: 20, width: 20};

addPropertyControls(FramerH7915NljF, {variant: {options: ["yLiQxo5kr", "ckyPSVg2w"], optionTitles: ["Menu Close", "Menu Open"], title: "Variant", type: ControlType.Enum}, cysd_f03b: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerH7915NljF, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})